import { Injectable } from '@angular/core';
import {
  translate,
} from '@ngneat/transloco';
import { BaseLanguageService } from './base-language.service';

@Injectable({ providedIn: 'root' })
export class TranslateService extends BaseLanguageService {
  translateInstant(key: string): string {
    return translate(key);
  }
}
